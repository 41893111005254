<template>
<section class="goods-group">
    <div class="goods-header flex space-between">
        <h2 class="title">产品介绍</h2>
        <p class="add-good" @click="addGoodHandler">
            <span class="el-icon el-icon-plus"></span>
            <span>添加商品</span>
        </p>
    </div>

    <!-- table -->
    <el-table
        class="table-wrapper"
        :data="list"
        :header-cell-style="{color: '#000',fontWeigth: 'bold',background: '#FAFAFA',textAlign:'center'}"
        style="width:100%;">
        <el-table-column
            label="商品名称"
            width="350">
            <template slot-scope="scope">
                <div class="flex">
                    <el-image :src="scope.row.mainImg" alt="" class="table-img">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <p class="table-name">
                        <span>{{scope.row.productName}}</span>
                    </p>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="quantity[0].count"
            align="center"
            label="库存">
        </el-table-column>
        <el-table-column
            align="center"
            label="价格">
            <template slot-scope="scope">
                ￥{{scope.row.price}}
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            type="index"
            label="排序">
        </el-table-column>
        <el-table-column
            align="center"
            label="操作">
            <template slot-scope="scope">
                <span class="table-btn" @click="sortHandler(scope.$index)">排序</span>
                <span class="table-btn" @click="delGoodHandler(scope.row._id, scope.$index)">删除</span>
            </template>
        </el-table-column>
    </el-table>

    <!-- 添加商品 -->
    <el-dialog title="添加商品" :visible.sync="dialogGoodVisible">
        <el-checkbox v-show="goodsList.length>0" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" label="全选"></el-checkbox>
        <el-checkbox-group v-model="checkedGoods" @change="checkedGoodsHandler">
            <div class="checkbox-item" v-for="(item,i) in goodsList" :key="i">
                <el-checkbox class="v-m" :label="item._id"></el-checkbox>
                <div class="good-info i-b v-m">
                    <el-image :src="item.mainImg" alt="" class="thumb v-m">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div></el-image> 
                    <h3 class="title i-b v-m">{{item.productName}}</h3>
                </div>
            </div>
        </el-checkbox-group>
        <p v-if="goodsList.length==0">没有商品数据~</p>
        <div slot="footer" class="dialog-footer">
            <el-button @click="addGoodCancelHandler">取 消</el-button>
            <el-button type="primary" @click="addGoodSureHandler">确 定</el-button>
        </div>
    </el-dialog>

    <!-- 排序 -->
    <el-dialog title="排序" :visible.sync="dialogSortVisible">
        <el-form :model="sortForm" :rules="sortRules" label-width="100px" ref="sortForm">
            <el-form-item label="排序" prop="newSort">
                <el-input v-model="sortForm.newSort" placeholder="请输入排序序号，如：1"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogSortVisible = false">取 消</el-button>
            <el-button type="primary" @click="sortSubmitHandler">确 定</el-button>
        </div>
    </el-dialog>
</section>
</template>

<script>
let groupId;
let currentSort = 0; // 当前的排序
let detail = {};
export default {
    data(){
        return {
            list:[],
            dialogGoodVisible: false,
            dialogSortVisible: false,
            checkedGoods:[],
            allGoods:[], // 所有商品列表，
            goodsList:[], // 过滤已经添加进组的商品
            sortForm:{
                newSort: ''
            },
            sortRules:{
                name:[{required: true, message: '请输入排序序号', trigger: 'blur'}]
            },
            isIndeterminate: false,
            checkAll: false,

        }
    },
    async created(){
        
        this.getGroupDetail();
        this.getAllGoods();
    },
    filters:{
        price(skuList){
            if(!skuList) return 0;
            const arr = skuList.sort((a,b)=>a.price-b.price);
            const len = arr.length;
            if(len==1){
                return arr[0].price;
            }else{
                return `${arr[0].price} ~ ${arr[len-1].price}`
            }
        }
    },
    methods:{
        Price(skuList){
            if(!skuList) return 0;
            const arr = skuList.sort((a,b)=>a.price-b.price);
            const len = arr.length;
            if(len==1){
                return arr[0].price;
            }else{
                return `${arr[0].price} ~ ${arr[len-1].price}`
            }
        },
        async getGroupDetail(){
            //  获取分组详情
            const res = await this.$https.cloud({
                                action:'group',
                                type:'detail',
                                formData:{
                                    _id: '产品介绍'
                                }
                            });
            if(res.code==0){
                groupId = res.data._id;
                detail = res.data;
                console.log('detail',detail);
                const sortGoods = [];
                const newGoodsList = [];
                // for(let i=0;i<detail.goodsList.length;i++){
                //     console.log('item--',detail.goodsList[i]);

                //     for(let k=0;k<detail.goods.length;k++){
                //         console.log(k);
                //         if(detail.goods[k]._id == detail.goodsList[i]){
                //             sortGoods.push(detail.goods[k]);
                //             console.log('good--',detail.goods[k]._id);
                //         }
                //     }
                // }


                detail.goodsList.forEach(item=>{
                    detail.goods.forEach(good=>{
                        if(good._id == item){
                            sortGoods.push(good);
                            newGoodsList.push(good._id);
                        }
                    });
                });
                console.log('sortGoods',sortGoods);
                detail.goods = sortGoods;
                console.log(detail.goods)
                this.list = res.data.goods;
                const imgList = [];
                this.list.forEach(item=>{
                    imgList.push({fileid:item.mainImg});
                    item.price = this.Price(item.skuList);
                });
                const fileList = await this.$https.getImagePath(imgList);
                this.list.forEach((item,i)=>{
                    item.mainImg = fileList[i].download_url;
                });
            }
        },
        async getAllGoods(){
            //  获取所有的商品
            const res = await this.$https.cloud({
                                action:'goods',
                                type:'all',
                            });
            if(res.code!=0) return;
            
            this.allGoods = res.data.data;
            const imgList = [];
            this.allGoods.forEach(item=>{
                imgList.push({fileid:item.mainImg});
            });
            const fileList = await this.$https.getImagePath(imgList);
            this.allGoods.forEach((item,i)=>{
                item.mainImg = fileList[i].download_url;
            });
            
        },
        sortHandler(i){
            currentSort = i;
            this.dialogSortVisible = true;

        },
        sortSubmitHandler(){
            this.$refs.sortForm.validate(async (valid)=>{
                if(!valid) return;
                if(this.sortForm.newSort<=0 || Number.isNaN(+this.sortForm.newSort)){
                    this.$message.error('请输入正确的序号');
                    return;
                }
                const res = await this.$https.cloud({
                                action:'group',
                                type:'sort',
                                formData:{
                                    oldSort: currentSort,
                                    newSort: (parseInt(this.sortForm.newSort-1)),
                                    _id: groupId
                                }
                            });
                if(res.code!=0){
                    this.$message.error(res.msg);
                    return;
                } 
                this.$message.success('排序成功');
                const item = this.list.splice(currentSort,1);
                this.list.splice(this.sortForm.newSort-1,0,item[0]);
                console.log(item);  
                this.dialogSortVisible = false;
            })
        },
        async delGoodHandler(id,i){
            //  从分组中移除商品
            let goodId = id;
            const res = await this.$https.cloud({
                                action:'group',
                                type:'delGood',
                                formData:{
                                    _id: groupId,
                                    goodId: goodId
                                }
                            });
            if(res.code!=0) return;
            this.$message('商品移除成功');
            this.list.splice(i,1);
        },
        checkedGoodsHandler(value){
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.goodsList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.goodsList.length;
        },
        addGoodHandler(){
            //  打开添加商品弹窗
            console.log(this.allGoods,arr)
            const arr = [];
            this.allGoods.forEach(item=>{
                if(!detail.goodsList.includes(item._id)){
                    arr.push(item);
                }
            });
            
            this.goodsList = arr;
            this.dialogGoodVisible = true;
        },
        addGoodCancelHandler(){
            // 取消添加商品
            this.checkedGoods = [];
            this.dialogGoodVisible = false; 
        },
        async addGoodSureHandler(){
            //  确认添加商品
            if(this.checkedGoods.length==0) {
                this.dialogGoodVisible = false; 
                return;
            }
            const rs = await this.$https.cloud({
                action:'group',
                type:'addGood',
                formData:{
                    _id: detail._id,
                    goodsList: this.checkedGoods
                }
            })
            if(rs.code==0) this.getGroupDetail();
            this.$message(rs.msg);
            this.checkedGoods = [];
            this.dialogGoodVisible = false; 
            
        },
        handleCheckAllChange(value){
            console.log(value);
            console.log(this.goodsList);
            if(value){
                this.goodsList.forEach(good=>{
                    this.checkedGoods.push(good._id);
                })
            }else{
                this.checkedGoods = [];
            }
            this.isIndeterminate = false;
            console.log(this.checkedGoods)
        }

    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common.scss';

.goods-group {
    padding:  0 10px;
    background: #fff;
    
    .goods-header {
        line-height: 68px;
        align-items: center;
        background: #fff;
        
        .title {
            font-size: 16px;
        }

        .add-good {
            display: inline-block;
            width:111px;
            height:32px;
            line-height: 32px;
            border: 1px solid $baseColor;
            border-radius:4px;
            font-size: 14px;
            color: $baseColor;
            text-align: center;

            
        }
    }

    .table-wrapper {
        border-top: 1px solid #ccc;

        .table-img {
            width: 45px;
            margin-right: 0.8rem;
        }

        .table-name {
            font-size: 14px;
            line-height: 22px;
            flex: 1;
        }

        .table-btn {
            color: $baseColor;
            padding: 0 6px;
        }
    }

    .checkbox-item {
        padding: 10px;
        border-bottom: 1px solid #ccc;
        ::v-deep.el-checkbox__label {
            display: none;
        }
        .good-info {
            margin-left: 10px;
        }

        .thumb {
            width: 44px;
            height: 44px;
            margin-right: 10px;
        }

        .title {
            font-size: 14px;
            line-height: 22px;
        }
    }
}
</style>